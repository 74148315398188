<template>
  <div class="container">
    <navbar
      title="霄鸟云集采中心"
      :leftArrow="false"
    ></navbar>
    <c-sticky v-if="orders.length" :offset-top="$px2vwMin(92)">
      <vertical-notice>
        <van-swipe-item v-for="(item, index) in orders" :key="index"><span class="notice-f">{{item.factory_name}}</span><span class="notice-d"> 下单了 </span><span class="notice-t">{{item.toy_name}}</span></van-swipe-item>
      </vertical-notice>
    </c-sticky>
    <div class="top-container">
      <div class="search-main">
        <search-input
          v-model="keyword"
          @search="search"
        ></search-input>
      </div>
      <div class="image-swipe" v-if="imgList.length > 0">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="(img, i) in imgList"
            :key="i"
          >
            <img :src="img.path" @click="JumpTo(img.jump_path)"/>
          </swiper-slide>
          <div
            class="swiper-pagination"
            slot="pagination"
          ></div>
        </swiper>
      </div>
    </div>
    <div class="toy-list">
      <toy-list
        @load="onLoad"
        :loading="loading"
        :finished="finished"
        :list="list"
      ></toy-list>
    </div>
  </div>
</template>

<script>
import { carousel } from "@/api/index.js";
import { toyList,toyOrderTmp } from "@/api/toy.js";
import Navbar from "@/components/navbar.vue";
import SearchInput from "@/components/search-input.vue";
import ToyList from "@/components/toy-list.vue";
import { mapGetters } from 'vuex'
import VerticalNotice from '@/components/vertical-notice'
import CSticky from "@/components/c-sticky.vue";

export default {
  name: "index",
  components: {
    Navbar,
    SearchInput,
    ToyList,
    VerticalNotice,
    CSticky
  },
  data() {
    return {
      keyword: '',
      loading: false,
      finished: false,
      list: [],
      imgList: [],
      page: 0,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
      },
      orders: []
    };
  },
  computed: {
    ...mapGetters({user: 'user/getUser'})
  },
  mounted() {
    this.getCarousel();
    document.title = '霄鸟云集采中心'
    this.getOrders();
  },
  methods: {
    search() {
      this.$router.push("/searchToy/" + this.keyword);
    },
    getCarousel() {
      carousel({}).then(res => {
        if (res.code == 0) {
          this.imgList = res.data;
        }
      })
    },
    getOrders() {
      toyOrderTmp()
        .then(res => {
          if (res.code == 0 && res.data) {
            this.orders = res.data
          }
        })
    },
    onLoad() {
      this.loading = true;
      this.page++;
      let data = { page: this.page, name: this.keyword };
      toyList(data).then(res => {
        if (res.code == 0) {
          if (res.data.length == 0) {
            this.finished = true;
          }

          if (this.page == 1) {
            this.list = res.data;
          } else {
            this.list = [...this.list, ...res.data];
          }
          this.loading = false;
        } else {
          this.$toast(res.msg);
        }
      })
    },
    JumpTo(path) {
      if (path) {
        if (path.indexOf('credit/apply') > -1 && (!this.user.isMain || !this.user.qualification) && this.user.applyStatus == 0) {
          // 主账号才能申请
          if (!this.user.isMain) return this.$toast('主账号才能申请')
          return this.$toast('只有会员工厂才能申请')
        } else if(path.indexOf('credit/apply') > -1 && this.user.applyStatus == 2) {
          return ;
        }
        window.location.href = path
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  .top-container {
    padding: 0 20px;
    height: auto;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    display: flex;
    flex-direction: column;
    .search-main {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .image-swipe {
      height: 237px;
      //   background: #4099ff;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 20px;
      img {
        height: 237px;
        width: 100%;
        object-fit: contain;
      }
      // :deep(.van-swipe__indicator) {
      //   width: 12px;
      //   height: 12px;
      //   border-radius: 6px;
      // }
      // :deep(.van-swipe__indicator.van-swipe__indicator--active) {
      //   width: 30px;
      //   height: 12px;
      //   border-radius: 6px;
      // }

      :deep(.swiper-pagination-bullet) {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        // background: rgba(255, 255, 255, 0.54);
      }
      :deep(.swiper-pagination-bullet-active) {
        width: 30px;
        height: 12px;
        border-radius: 6px;
        background: #ffffff;
      }
    }
  }
  .toy-list {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .notice-f{
    color: #666666;
  }
  .notice-d{
    color: #999999;
  }
  .notice-t{
    color: #0090FF;
  }
}
</style>
